@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

/*
@font-face {
  font-family: FontAwesome;
  src: url(./fonts/FontAwesome.ttf);
}
*/
/*
@font-face {
    font-family: Material WeatherIconsTypes;
    src: url(./fonts/MaterialIcons.ttf);
}
*/
body {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: MaterialCommunityIcons;
  /*font-family: Material Design Icons;*/
  src: url(./fonts/MaterialCommunityIcons.ttf);
}

@font-face {
  font-family: icomoon;
  src: url(./fonts/icomoon.ttf);
}

#root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

* {
  font-family: 'Roboto', sans-serif;

  border-style: none;
  border-width: 0;
  overflow: hidden;

  flex-direction: column; /* react native default */
}

/*
*:not(input):not(textarea), *:focus:not(input):not(textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
}
*/

.rn-zIndex-1lgpqti {
  /*z-index: auto !important;*/
}

.link {
  cursor: pointer;
}

textarea:focus, input:focus, button:focus, *:focus {
  outline: none;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button {
  background-color: transparent;
}

th {
  text-align: left;
}

textarea {
  overflow: auto;
  /*resize: vertical !important;*/
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.elevation1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.elevation2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.elevation3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.elevation4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.elevation5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.leaflet-container {
  overflow: visible !important;
}
.leaflet-container * {
  overflow: visible !important;
}
.leaflet-pane {
  overflow: visible !important;
}

.leaflet-tooltip {
  padding: 0px !important;
  border-radius: 6px !important;
  overflow: hidden !important;
}

.leaflet-tooltip * {
  overflow: hidden !important;
}

.leaflet-popup * {
  overflow: hidden !important;
}
.mapboxgl-popup, .maplibregl-popup {
  overflow: visible!important;
}

.maplibregl-popup-content, .mapboxgl-popup-content {
  padding: 0!important;
  border-radius: 6px;
}

.mapboxgl-ctrl-icon {
  width: 29px!important;
}
/*
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px transparent inset;
}
*/

/*Change text in autofill textbox
input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
}
*/
