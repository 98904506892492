.tie-btn-deleteAll, .tie-btn-history {
  display: none !important;
}

.tui-image-editor-help-menu.top {
  width: auto !important;
}
@media only screen and (max-width: 500px) {
  .tie-btn-delete, .tie-btn-redo, .tui-image-editor-icpartition {
    display: none !important;
  }
}
