.tui-image-editor-header {
  display: none;
}

.tui-image-editor-submenu {
  overflow: visible;
}

.tui-image-editor-submenu * {
  overflow: visible;
}
